import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react'
import { useCallback } from 'react'

import { Font } from 'modules/api'

import { useDeleteFont } from '../hooks'

type FontDeleteModalProps = {
  onClose: () => void
  font: Font
  orgId: string
}
export const FontDeleteModal = ({
  onClose,
  font,
  orgId,
}: FontDeleteModalProps) => {
  const { archiveFontFn, isArchiving } = useDeleteFont(orgId)

  const onFontDelete = useCallback(() => {
    archiveFontFn(font.id, font.name)
      .then(onClose)
      .catch((err) => {
        // TODO display an error state
        console.error('[FontDeleteModal] Error deleting font', err)
      })
  }, [archiveFontFn, font.id, font.name, onClose])

  return (
    <Modal onClose={onClose} isOpen={true}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete {font.name}?</ModalHeader>
        <ModalCloseButton disabled={isArchiving} />
        <ModalBody>
          <Stack spacing={4}>
            <Text fontWeight="bold">
              Are you sure you want to delete this font family?
            </Text>
            <Text>
              Any custom themes using this font will not break, but they will
              not be able to select it again
            </Text>
            <Text color="red.600">This action cannot be undone</Text>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="ghost"
              color="red.600"
              onClick={onFontDelete}
              isLoading={isArchiving}
            >
              Delete font family
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
