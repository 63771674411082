import { ButtonProps, LinkProps } from '@chakra-ui/react'
import NextLink from 'next/link'
import React, { MutableRefObject } from 'react'

import { SidebarButton } from './SidebarButton'

interface SidebarItemProps extends ButtonProps {
  tabKey: string
  isSelected?: boolean
  isDropActive?: boolean
  href: string
  target?: LinkProps['target']
}

export const SidebarItem = React.forwardRef(
  (
    {
      tabKey,
      isSelected,
      isDropActive = false,
      href,
      target,
      children,
      ...props
    }: SidebarItemProps,
    ref: MutableRefObject<HTMLButtonElement>
  ) => {
    return (
      <NextLink href={href} passHref>
        <SidebarButton
          ref={ref}
          isSelected={isSelected}
          isDropActive={isDropActive}
          dataTestId={`sidebar-${tabKey}-tab${isSelected ? '--selected' : ''}`}
          target={target}
          {...props}
        >
          {children}
        </SidebarButton>
      </NextLink>
    )
  }
)

SidebarItem.displayName = 'SidebarItem'
