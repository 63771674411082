import { analytics, SegmentEvents } from 'modules/segment'
import { localStore } from 'utils/storage'
import { USER_SETTINGS_CONSTANTS } from 'utils/userSettingsConstants'

type CreateDocArgs = {
  emptyDocType: 'quickstart' | 'empty'
  queryParams: {
    tour?: boolean
  } | null
}

type FirstDocVariation =
  | 'quickstartCard'
  | 'showTour'
  | 'quickStartCardShowTour'
  | null

const quickStartVariations: FirstDocVariation[] = [
  'quickstartCard',
  'quickStartCardShowTour',
]
const tourVariations: FirstDocVariation[] = [
  'showTour',
  'quickStartCardShowTour',
]

/**
 * - Returns params for creating a new doc
 * - Sets localStorage flag if user was bucketed
 * - Adds segment tracking
 */
export const makeCreateFirstDocExperiment = ({
  docCount,
  forceBucket,
}: {
  docCount?: number
  forceBucket?: boolean
}): CreateDocArgs => {
  const rand = Math.random()
  const shouldBucket =
    !localStore.getItem(USER_SETTINGS_CONSTANTS.hasSeenCreateFirstDocExp) &&
    (forceBucket || (docCount && docCount <= 1))
  const variation: FirstDocVariation = !shouldBucket
    ? null
    : rand > 0.6666
    ? 'quickstartCard'
    : rand > 0.3333
    ? 'showTour'
    : 'quickStartCardShowTour'

  const emptyDocType = quickStartVariations.includes(variation || null)
    ? 'quickstart'
    : 'empty'
  const queryParams = tourVariations.includes(variation || null)
    ? { tour: true }
    : null

  // Means they were eligible for experiment and we bucketed them
  if (variation) {
    analytics?.track(SegmentEvents.CREATE_FIRST_DOC_EXP_BUCKETED, {
      variation,
    })
    localStore.setItem(USER_SETTINGS_CONSTANTS.hasSeenCreateFirstDocExp, 'true')
  }

  return {
    emptyDocType,
    queryParams,
  }
}
