import {
  Box,
  Flex,
  GridItem,
  Heading,
  HStack,
  Image,
  SimpleGrid,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'

import { useGetThemesQuery } from 'modules/api'
import { useRequiredFeatureFlag } from 'modules/featureFlags/hooks/useRequiredFeatureFlag'
import { useModalDisclosure } from 'modules/modal_state/hooks/useModalDisclosure'
import { emptyTheme, Theme, ThemeType } from 'modules/theming'
import { ThemeEditorDrawer } from 'modules/theming/components/ThemeEditorDrawer'
import { ThemePreview } from 'modules/theming/components/ThemePreview'
import { GlobalCardStyles } from 'modules/tiptap_editor/extensions/Card/GlobalCardStyles'
import { useUserContext } from 'modules/user'
import SalPainting from 'publicImages/Sal-Painting-2x.png'

import { SidebarTabs, TabMap } from '../Sidebar'
import { TopbarWrapper } from '../Topbar'
import { GridItemButton } from './GridItemButton'

type ThemesViewProps = {
  isSidebarVisible: boolean
  setIsSidebarVisible: Dispatch<SetStateAction<boolean>>
}

export const ThemesView = ({
  isSidebarVisible,
  setIsSidebarVisible,
}: ThemesViewProps) => {
  const [theme, setTheme] = useState<Theme>(emptyTheme)
  const { currentWorkspace } = useUserContext()
  const {
    isOpen: isThemeEditorOpen,
    onOpen: onThemeEditorOpen,
    onClose: onThemeEditorClose,
  } = useModalDisclosure({ id: 'themeEditor' })

  useRequiredFeatureFlag('themesHomescreen')

  const openThemeEditor = useCallback(
    (themeToEdit?: Theme) => {
      setTheme(themeToEdit || emptyTheme)
      onThemeEditorOpen()
    },
    [onThemeEditorOpen]
  )

  const closeThemeEditor = useCallback(() => {
    setTheme(emptyTheme)
    onThemeEditorClose()
  }, [onThemeEditorClose])

  const {
    data: workspaceData,
    loading,
    called,
  } = useGetThemesQuery({
    variables: {
      workspaceId: currentWorkspace?.id,
      archived: false,
    },
    skip: !currentWorkspace,
  })
  const { data: standardData } = useGetThemesQuery({
    variables: {
      // @ts-ignore
      workspaceId: null,
      archived: false,
    },
  })

  const showLoading = !called || loading
  const workspaceThemes = workspaceData?.themes || []
  const standardThemes = standardData?.themes || []

  const { displayName, description, icon } = TabMap[SidebarTabs.THEMES]

  return (
    <Box w="100%" data-testid="themes-view-container">
      <GlobalCardStyles />
      <TopbarWrapper
        isSidebarVisible={isSidebarVisible}
        setIsSidebarVisible={setIsSidebarVisible}
      >
        <Heading
          fontSize="lg"
          fontWeight="600"
          data-testid="themes-view-header"
        >
          {icon && <FontAwesomeIcon icon={icon} aria-label={displayName} />}{' '}
          <Text as="span" ml={2}>
            {displayName}
          </Text>
        </Heading>
        <Text fontSize="md" mt="2" mb="6" color="gray.600">
          {description}
        </Text>
      </TopbarWrapper>
      {showLoading ? (
        <Box width="100%" data-testid="themes-skeleton">
          <SimpleGrid columns={[1, 1, 2, 3, 5]} spacing={6}>
            {[1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
              <GridItem
                key={i}
                bg="linen.50"
                borderRadius="5px"
                height="144px"
                as={Skeleton}
              />
            ))}
          </SimpleGrid>
        </Box>
      ) : (
        <>
          <ThemesGrid
            themes={workspaceThemes}
            heading="Custom themes"
            description="These themes are custom to your workspace."
            openThemeEditor={openThemeEditor}
            type="custom"
          />
          <ThemesGrid
            themes={standardThemes}
            heading="Standard themes"
            description="These themes are created by Gamma."
            openThemeEditor={openThemeEditor}
            type="standard"
          />
        </>
      )}
      <EditorDrawer
        theme={theme}
        isThemeEditorOpen={isThemeEditorOpen}
        onThemeEditorClose={closeThemeEditor}
      />
    </Box>
  )
}

type ThemesGridProps = {
  themes: Theme[]
  heading: string
  description: string
  type: ThemeType
  openThemeEditor?: (themeToEdit?: Theme | undefined) => void
}

const ThemesGrid = ({
  themes,
  type,
  heading,
  description,
  openThemeEditor,
}: ThemesGridProps) => {
  const isCustom = type === 'custom'
  const showEmptyState = themes.length === 0 && isCustom

  return (
    <Stack my={12}>
      <Stack mb={6} fontSize="md">
        <Heading fontSize="2xl" mb={-1}>
          {heading}
        </Heading>
        {!showEmptyState && <Text color="gray.600">{description}</Text>}
      </Stack>
      {showEmptyState ? (
        <EmptyState openThemeEditor={openThemeEditor} />
      ) : (
        <SimpleGrid columns={[1, 1, 2, 3, 5]} spacing={6}>
          {isCustom && (
            <GridItemButton
              label="New theme"
              icon={<FontAwesomeIcon icon={regular('circle-plus')} />}
              onClick={() => openThemeEditor?.()}
            />
          )}
          {themes.map((theme) => {
            return (
              <ThemePreview
                key={theme.id}
                type={type}
                theme={theme}
                isChecked={false}
                onEditClick={openThemeEditor}
                onThemeClicked={isCustom ? openThemeEditor : undefined}
              />
            )
          })}
        </SimpleGrid>
      )}
    </Stack>
  )
}

type EditorDrawerProps = {
  isThemeEditorOpen: boolean
  onThemeEditorClose: () => void
  theme: Theme
}
const EditorDrawer = ({
  isThemeEditorOpen,
  onThemeEditorClose,
  theme,
}: EditorDrawerProps) => {
  const [themeToEdit, setThemeToEdit] = useState<Theme>(theme)

  useEffect(() => {
    setThemeToEdit(theme)
  }, [theme])

  return (
    <ThemeEditorDrawer
      isOpen={isThemeEditorOpen}
      onClose={onThemeEditorClose}
      theme={themeToEdit}
      setTheme={setThemeToEdit}
      disableDrawerTransition
    />
  )
}

const EmptyState = ({ openThemeEditor }) => {
  return (
    <HStack
      bg="gray.100"
      p={4}
      textAlign="left"
      spacing={4}
      borderRadius="md"
      width="100%"
      my={2}
    >
      <Flex
        direction="column"
        width="50%"
        alignItems="center"
        justifyContent="center"
      >
        <Image
          src={SalPainting.src}
          width="200px"
          alt="Sal, the Gamma mascot, painting on the wall."
        />
      </Flex>
      <Flex
        direction="column"
        width="350px"
        alignItems="center"
        justifyContent="center"
      >
        <Text p={2} fontWeight="700" w="75%">
          Create your first theme
        </Text>
        <Text px={2} fontSize="sm" color="gray.700" w="75%">
          Create a beautiful, branded theme. Just pick your fonts, color, and
          background, and upload your logo
        </Text>
        <Flex mt={4} p={0} w="75%">
          <GridItemButton
            label="New theme"
            icon={<FontAwesomeIcon icon={regular('circle-plus')} />}
            onClick={() => openThemeEditor?.()}
          />
        </Flex>
      </Flex>
    </HStack>
  )
}
