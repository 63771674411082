import {
  Button,
  ButtonGroup,
  Divider,
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react'
import { nanoid } from 'nanoid'
import { useCallback, useEffect, useState } from 'react'

import { ExistingWorkspace, Font } from 'modules/api'
import { fontTypes } from 'modules/media/apis/transloadit'

import { useFontUploadReducer } from '../fontUploadReducer/reducer'
import { FontUploadReducerState } from '../fontUploadReducer/types'
import { useSaveFont } from '../hooks'
import { makeRetryablePromise } from '../utils/promise'
import {
  getAcceptedFontExtension,
  getStyleCount,
  isFontDirty,
  parseFontFile,
} from '../utils/utils'
import { FontCategorizationView } from './FontCategorizationView'
import { FontUploadInfoView } from './FontUploadInfoView'
import { uploadFontFile } from './uploadFontFiles'
import { useHiddenFileInput } from './useHiddenFileInput'

type FontUploadModalProps = {
  onClose: () => void
  orgId: ExistingWorkspace['id']
  readonly font: Font | null
}

const determineCanSave = (state: FontUploadReducerState) => {
  const isAllOkay = state.uploadFontFiles.every((f) => {
    return (
      (f.status === 'created' || f.status === 'existing') &&
      f.fontFile.sourceUrl
    )
  })
  return state.fontName && isAllOkay
}
export const FontUploadModal = ({
  onClose,
  orgId,
  font,
}: FontUploadModalProps) => {
  const [state, dispatch] = useFontUploadReducer()
  useEffect(() => {
    if (font) {
      dispatch({
        type: 'EDIT_EXISTING_FONT',
        data: { font },
      })
    } else {
      dispatch({
        type: 'NEW_FONT_UPLOAD',
      })
    }
  }, [dispatch, font])

  const [isLoading, setIsLoading] = useState(false)
  const canSave = determineCanSave(state)
  const saveFont = useSaveFont(orgId)
  const isDirty = isFontDirty(state)

  const handleModalClose = useCallback(() => {
    dispatch({
      type: 'RESET_FONT_UPLOAD',
    })
    onClose()
  }, [dispatch, onClose])

  const handleFilesUpload = useCallback(
    (fontFiles: File[]) => {
      Promise.all(
        // TODO handle excessive font uploads
        fontFiles.map(async (fontFile) => {
          const uploadId = nanoid()
          const parsedFontFile = await parseFontFile(fontFile)

          dispatch({
            type: 'UPLOAD_FONT_FILE_UPLOAD_STARTED',
            data: {
              uploadId,
              fileType: getAcceptedFontExtension(fontFile.name),
              name: fontFile.name,
              ...parsedFontFile,
            },
          })

          try {
            const sourceUrl = await makeRetryablePromise(
              () => uploadFontFile(orgId, fontFile),
              { maxRetries: 3, timeout: 20000 }
            )
            dispatch({
              type: 'UPLOAD_FONT_FILE_UPLOAD_SUCCESS',
              data: {
                uploadId,
                sourceUrl,
              },
            })
          } catch (err) {
            const errorMessage =
              err instanceof Error
                ? err.message
                : `There was an error uploading ${fontFile.name}`
            dispatch({
              type: 'UPLOAD_FONT_FILE_UPLOAD_ERROR',
              data: {
                uploadId,
                errorMessage,
              },
            })
          }
        })
      ).catch((err) => {
        console.error('[FontUploadModal] error while uploading font files', err)
      })
    },
    [dispatch, orgId]
  )

  const { onClick: onFontFileUploadClick, inputElement: hiddenInputElement } =
    useHiddenFileInput({
      handleFilesCallback: handleFilesUpload,
      multiple: true,
      accept: fontTypes.join(','),
    })

  const toast = useToast()
  const onSave = useCallback(() => {
    setIsLoading(true)
    saveFont(state)
      .then(() => {
        if (state.mode === 'create') {
          toast({
            title: `Created ${state.fontName}`,
            status: 'success',
            duration: 1000,
            isClosable: true,
            position: 'top',
          })
        } else if (state.mode === 'edit') {
          toast({
            title: `Successfully updated ${state.fontName}`,
            status: 'success',
            duration: 2000,
            isClosable: true,
            position: 'top',
          })
        }
      })
      .catch((err) => {
        toast({
          title: `Error ${state.mode === 'edit' ? 'editing' : 'creating'} ${
            state.fontName
          }`,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top',
        })
        console.error(
          `[FontUploadModal] error ${
            state.mode === 'edit' ? 'editing' : 'creating'
          } font`,
          err
        )
      })
      .finally(() => {
        setIsLoading(false)
        onClose()
      })
  }, [onClose, saveFont, state, toast])

  const numOfStyles = getStyleCount(
    state.uploadFontFiles.map((a) => a.fontFile)
  )

  return (
    <Modal
      isOpen={true}
      closeOnOverlayClick={false}
      onClose={handleModalClose}
      size="3xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {state.hasUploadedFonts
            ? state.fontName
              ? state.fontName
              : 'Untitled font family'
            : 'Upload font family'}
        </ModalHeader>
        <ModalCloseButton disabled={isLoading} />
        <Divider mb="4" />

        {hiddenInputElement}

        {/* Initial uploading screen with instructions */}
        {!state.hasUploadedFonts ? (
          <FontUploadInfoView onFontFileUploadClick={onFontFileUploadClick} />
        ) : (
          <FontCategorizationView
            fontFiles={state.uploadFontFiles}
            fontName={state.fontName}
            dispatch={dispatch}
            onFontFileUploadClick={onFontFileUploadClick}
          />
        )}

        <Divider mb={1} />

        <ModalFooter>
          {!state.hasUploadedFonts ? (
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          ) : (
            <Flex
              flex={1}
              mb="2"
              alignItems="center"
              justifyContent="space-between"
            >
              <Text>
                {numOfStyles} {numOfStyles === 1 ? 'style' : 'styles'}
              </Text>
              <ButtonGroup>
                <Button variant="ghost" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  isDisabled={!canSave || !isDirty}
                  variant="solid"
                  onClick={onSave}
                  isLoading={isLoading}
                >
                  {state.mode === 'edit' ? 'Save' : 'Create font family'}
                </Button>
              </ButtonGroup>
            </Flex>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
