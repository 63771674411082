import { Box, Flex, GridItem, Link, Stack, Text } from '@chakra-ui/react'
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome'
import NextLink from 'next/link'

type GetStartedOptionProps = {
  title: string
  body: React.ReactNode
  accentColor: string
  href: string
  icon: FontAwesomeIconProps['icon']
  bgImage?: string
  onClick: (e: React.MouseEvent) => void
}

export const GetStartedOption = ({
  title,
  body,
  accentColor,
  href,
  icon,
  bgImage,
  onClick,
}: GetStartedOptionProps) => {
  return (
    <NextLink href={href} passHref>
      <GridItem
        as={Link}
        position="relative"
        padding="6"
        border="2px solid"
        borderColor="gray.100"
        borderRadius="8"
        cursor="pointer"
        boxSizing="border-box"
        background="white"
        shadow="sm"
        transitionProperty="common"
        transitionDuration="normal"
        _hover={{
          borderColor: `${accentColor}.400`,
          boxShadow: 'xl',
          textDecoration: 'none',
        }}
        role="group"
        onClick={onClick}
      >
        <Stack>
          <Flex
            align="center"
            justify="center"
            height="40"
            mb="2"
            borderRadius="md"
            position="relative"
            backgroundImage={`url("${bgImage}")`}
            backgroundSize="cover"
            backgroundPosition="center"
            overflow="hidden"
            boxSizing="border-box"
            borderWidth="1px"
            borderColor="white"
            _groupHover={{
              opacity: 1,
              borderColor: 'blackAlpha.100',
            }}
            outline="2px solid white"
            outlineOffset="-1px"
          >
            <Flex
              pos="absolute"
              align="center"
              justify="center"
              top={0}
              left={0}
              bottom={0}
              right={0}
              background={`${accentColor}.100`}
              fontSize="3rem"
              color={`${accentColor}.500`}
              transitionProperty="all"
              transitionDuration="normal"
              borderRadius="0px"
              _groupHover={{
                right: 2,
                bottom: 2,
                left: 'calc(100% - 3rem)',
                top: 'calc(100% - 3rem)',
                borderRadius: '100',
                fontSize: '0.9em',
                shadow: 'md',
              }}
            >
              <Box>
                <FontAwesomeIcon icon={icon} className="fa-xl" />
              </Box>
            </Flex>
          </Flex>
          <Text mt={2} fontSize="xl" fontWeight="semibold" lineHeight="short">
            {title}
          </Text>
          <Text fontSize="sm">{body}</Text>
        </Stack>
      </GridItem>
    </NextLink>
  )
}
