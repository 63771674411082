import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  format,
  formatRelative,
  isToday,
  isYesterday,
  parseISO,
  subDays,
} from 'date-fns'
import { capitalize } from 'lodash'

import { ExistingWorkspace, useGetWorkspaceQuery } from 'modules/api'
import { useCan, useUserContext } from 'modules/user'

import { GeneralSettings } from './GeneralSettings'
import { InviteByEmail } from './InviteByEmail'
import { InviteLink } from './InviteLink'
import { MembersList } from './MembersList'
import { PendingInvitationsList } from './PendingInvitationsList'

const formatDate = (date: string) => {
  let dateToReturn = ''
  try {
    const parsed = parseISO(date)
    const sub = subDays(parsed, 0)
    if (isToday(parsed) || isYesterday(parsed)) {
      dateToReturn = formatRelative(sub, new Date())
    } else {
      dateToReturn = format(parsed, 'PPp')
    }
  } catch (e) {
    // swallow
  }
  return capitalize(dateToReturn)
}

export const WorkspaceSettingsModal = ({ isOpen, onClose }) => {
  const { currentWorkspace } = useUserContext()
  const isWorkspaceAdmin = useCan('manage', {
    ...(currentWorkspace as ExistingWorkspace),
    __typename: 'Organization',
  })

  const { data: workspaceData } = useGetWorkspaceQuery({
    variables: {
      id: currentWorkspace?.id as string,
    },
    skip: !currentWorkspace?.id,
  })
  const invitations = workspaceData?.workspace?.invitations || []

  if (!currentWorkspace) return null

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Workspace settings</ModalHeader>
        <ModalCloseButton />
        <Divider mb={4} />
        <ModalBody>
          <GeneralSettings
            workspace={currentWorkspace}
            isWorkspaceAdmin={isWorkspaceAdmin}
          />

          <Box mt={12}>
            <Stack spacing={8}>
              <Box>
                <Heading size="md" fontWeight="600" mb={4}>
                  Invite others to this workspace
                </Heading>
                <InviteByEmail
                  workspace={currentWorkspace}
                  isWorkspaceAdmin={isWorkspaceAdmin}
                />
                {isWorkspaceAdmin && (
                  <InviteLink workspace={currentWorkspace} />
                )}
              </Box>
              <Box>
                <Tabs variant="soft-rounded" size="sm">
                  <TabList mb={4}>
                    <Tab>
                      <Box mr={2}>
                        <FontAwesomeIcon icon={solid('people-group')} />
                      </Box>
                      Members
                    </Tab>
                    <Tab>
                      <Box mr={2}>
                        <FontAwesomeIcon icon={regular('envelope')} />
                      </Box>
                      Invitations
                    </Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel p={0} mt={0}>
                      <MembersList
                        workspace={currentWorkspace}
                        dateFormatterFn={formatDate}
                      />
                    </TabPanel>
                    <TabPanel p={0} mt={0}>
                      <PendingInvitationsList
                        invitations={invitations}
                        dateFormatterFn={formatDate}
                      />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Box>
            </Stack>
          </Box>
        </ModalBody>
        <Divider mt={6} />

        <ModalFooter>
          <ButtonGroup>
            <Button variant="solid" onClick={onClose}>
              Done
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
