import Uppy, { UploadResult } from '@uppy/core'
import Transloadit, { Assembly } from '@uppy/transloadit'

import { config } from 'config'
import { fontTypes, UPPY_CONFIG } from 'modules/media'

type FontUploadResult = UploadResult & {
  transloadit: Assembly[]
}

type SourceUrlWithSuccessStatus = {
  sourceUrl: string
  success: boolean
}

const getSourceUrlFromTransloaditUploadResult = (
  uploadResult: FontUploadResult | Assembly
): SourceUrlWithSuccessStatus => {
  let sourceUrl: string = ''
  let didUploadFail = false
  if ('transloadit' in uploadResult) {
    const { failed, transloadit } = uploadResult
    didUploadFail = Boolean(failed.length)
    sourceUrl = transloadit[0].results[':original'][0].url
  } else if ('results' in uploadResult) {
    sourceUrl = uploadResult.results[':original'][0].url
  } else {
    return { sourceUrl, success: false }
  }
  if (didUploadFail) {
    return { sourceUrl, success: false }
  }

  return { sourceUrl, success: true }
}

export const uploadFontFile = (orgId: string, file: File) => {
  const uppy = new Uppy({
    ...UPPY_CONFIG,
    restrictions: {
      ...UPPY_CONFIG.restrictions,
      allowedFileTypes: fontTypes,
    },
  })

  uppy.use(Transloadit, {
    params: {
      auth: { key: config.TRANSLOADIT_AUTH_KEY },
      template_id: config.TRANSLOADIT_FILE_TEMPLATE_ID,
    },
    waitForEncoding: true,
    fields: {
      orgId,
    },
  })

  return new Promise<string>((resolve, reject) => {
    // Fired when all uploads complete: https://uppy.io/docs/uppy/#complete
    uppy.on('complete', (result: FontUploadResult) => {
      const { success, sourceUrl } =
        getSourceUrlFromTransloaditUploadResult(result)
      if (success) {
        resolve(sourceUrl)
      } else {
        reject('Could not get sourceUrl from upload result')
      }
    })

    // Fired when an Assembly completed: https://uppy.io/docs/transloadit/#transloadit-complete
    uppy.on('transloadit:complete', (assembly: Assembly) => {
      const { success, sourceUrl } =
        getSourceUrlFromTransloaditUploadResult(assembly)
      if (success) {
        resolve(sourceUrl)
      } else {
        reject('Could not get sourceUrl from result')
      }
    })

    // Fired when Uppy fails to upload/encode the entire upload: https://uppy.io/docs/uppy/#error
    uppy.on('error', (error) => {
      reject(error)
    })

    // Fired when a file violates certain restrictions when added: https://uppy.io/docs/uppy/#restriction-failed
    uppy.on('restriction-failed', (failedFile, error) => {
      console.error(
        '[uploadFontFile] restriction failed on file',
        failedFile,
        'error',
        error
      )
      reject(error)
    })

    uppy.addFile({ name: file.name, type: file.type, data: file })
    // If autoProceed is true, uppy will upload automatically when files are added
    if (!UPPY_CONFIG.autoProceed) {
      uppy.upload()
    }
  })
}
