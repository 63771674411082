import { CloseIcon } from '@chakra-ui/icons'
import {
  Stack,
  Box,
  Divider,
  Flex,
  IconButton,
  Spacer,
  useDisclosure,
  Text,
  VStack,
} from '@chakra-ui/react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { DOC_DISPLAY_NAME_PLURAL, GammaTooltip } from '@gamma-app/ui'
import { Dispatch, SetStateAction } from 'react'
import { useDispatch } from 'react-redux'

import { Logo } from 'gamma_components'
import { Channel, User } from 'modules/api'
import { useFeatureFlag, useFeatureFlagProvider } from 'modules/featureFlags'
import { FEEDBACK_LINK } from 'modules/help/constants'
import { launchIntercomUI } from 'modules/intercom'
import { setIsIntercomOpen } from 'modules/intercom/reducer'
import { FauxSearchInput } from 'modules/search'
import { CHANNELS_ICON } from 'modules/sharing/constants'
import { WorkspaceSwitcher } from 'modules/workspaces'
import { WorkspaceSettingsModal } from 'modules/workspaces/components/WorkspaceSettingsModal'
import { isMobileDevice } from 'utils/deviceDetection'

import { SidebarButton } from './SidebarButton'
import { SidebarChannels } from './SidebarChannels'
import { SidebarItem } from './SidebarItem'
import { SidebarItemLabel } from './SidebarItemLabel'
import { SidebarSkeleton } from './SidebarSkeleton'

// These are Union Types (instead of enums)
// Read more here:
//   https://stackoverflow.com/a/60041791
//   https://blog.bam.tech/developer-news/should-you-use-enums-or-union-types-in-typescript
export const DocsTabs = {
  SHARED_WITH_ME: 'shared-with-me',
  ALL: 'all',
  CHANNEL: 'channel',
  TRASH: 'trash',
  EXAMPLES: 'examples',
  TEMPLATES: 'templates',
  INSPIRATION: 'inspiration',
  THEMES: 'themes',
  FONTS: 'fonts',
} as const
type DocsTabsType = typeof DocsTabs[keyof typeof DocsTabs]

const ActivityTabs = {
  MENTIONS: 'mentions',
  COMMENTS: 'comments',
} as const
type ActivityTabsType = typeof ActivityTabs[keyof typeof ActivityTabs]

export type SidebarTabsType = DocsTabsType | ActivityTabsType
export const SidebarTabs = { ...DocsTabs, ...ActivityTabs }

export interface SidebarTabGroups {
  DOCS: DocsTabsType
  ACTIVITY: ActivityTabsType
}

export type TabInfo = {
  displayName: string
  description?: React.ReactNode
  icon: IconProp
  timestampDescriptor?: string
  showCreateButton?: boolean
}

export const TabMap: Record<valueof<SidebarTabGroups>, TabInfo> = {
  // Only used for users with no Org in DocsView (not sidebar) for the time being
  [DocsTabs.SHARED_WITH_ME]: {
    displayName: 'Shared With Me',
    icon: regular('paper-plane'),
    showCreateButton: false,
  },
  [DocsTabs.ALL]: {
    displayName: `All ${DOC_DISPLAY_NAME_PLURAL}`,
    icon: regular('rectangle-history'),
    timestampDescriptor: 'Edited',
    showCreateButton: true,
  },
  [DocsTabs.CHANNEL]: {
    displayName: 'Channel',
    icon: CHANNELS_ICON,
    showCreateButton: true,
  },
  [DocsTabs.TRASH]: {
    displayName: 'Trash',
    icon: regular('trash'),
    showCreateButton: false,
  },
  [DocsTabs.EXAMPLES]: {
    displayName: 'Examples',
    icon: regular('gem'),
    showCreateButton: false,
  },
  [DocsTabs.TEMPLATES]: {
    displayName: 'Templates',
    icon: regular('bolt'),
    showCreateButton: false,
  },
  [DocsTabs.INSPIRATION]: {
    displayName: 'Inspiration',
    icon: regular('book-open'),
    showCreateButton: false,
  },
  [DocsTabs.THEMES]: {
    displayName: 'Themes',
    icon: regular('palette'),
    showCreateButton: false,
    description: (
      <>
        Gamma comes with <b>built in themes</b> to style your decks, but you can
        also create your own
      </>
    ),
  },
  [DocsTabs.FONTS]: {
    // To be eventually merged with themes
    displayName: 'Custom fonts',
    icon: regular('font-case'),
    showCreateButton: false,
    description: (
      <>
        Gamma comes with <b>Google Fonts</b>, but you can also upload your own
        to use in custom themes
      </>
    ),
  },
  [ActivityTabs.MENTIONS]: {
    displayName: 'Mentions',
    icon: regular('at'),
  },
  [ActivityTabs.COMMENTS]: {
    displayName: 'Comments',
    icon: regular('comment'),
  },
}

type SidebarProps = {
  channelPreview: Channel | null
  isSidebarVisible: boolean
  setIsSidebarVisible: Dispatch<SetStateAction<boolean>>
  user?: User
  isWorkspaceAdmin: boolean
  isReady: boolean
  channels: Channel[]
  isChannelsLoading: boolean
  activeTab: SidebarTabsType
  currentChannelId: string | null
}

export const Sidebar = ({
  channelPreview,
  isSidebarVisible,
  setIsSidebarVisible,
  isWorkspaceAdmin,
  isReady,
  activeTab,
  currentChannelId,
  channels,
  isChannelsLoading,
}: SidebarProps) => {
  const isIntercomEnabled = useFeatureFlag('intercom')
  const canOpenIntercom = isIntercomEnabled && !isMobileDevice
  const dispatch = useDispatch()

  const onContactClick = () => {
    launchIntercomUI()
    dispatch(setIsIntercomOpen({ isOpen: true }))
  }

  const {
    isOpen: isWorkspaceSettingsModalOpen,
    onOpen: onWorkspaceSettingsModalOpen,
    onClose: onWorkspaceSettingsModalClose,
  } = useDisclosure({ id: 'workspace-settings-modal' })
  return (
    <Flex
      direction="column"
      zIndex="overlay"
      h="100vh"
      position={{ base: 'fixed', md: 'sticky' }}
      top={0}
      w="300px"
      minW="300px"
      bg="white"
      shadow={{
        base: isSidebarVisible ? 'xl' : 'none',
        md: 'none',
      }}
      transform={
        isSidebarVisible
          ? {
              base: 'translate(0,0px)',
              md: 'translate(0,0)',
            }
          : {
              base: 'translate(-100%,0px)',
              md: 'translate(0,0)',
            }
      }
      transition="transform .2s ease"
    >
      <Flex
        overflowY="auto"
        h="100vh"
        p={6}
        py={2}
        direction="column"
        alignContent={'space-between'}
      >
        {!isReady ? (
          <SidebarSkeleton setIsSidebarVisible={setIsSidebarVisible} />
        ) : (
          <>
            <Stack>
              <Flex alignItems="flex-start" justify="flex-end">
                <Box
                  display={{
                    base: 'block',
                    md: 'none',
                  }}
                >
                  <IconButton
                    size="sm"
                    aria-label="Close sidebar"
                    variant="ghost"
                    icon={<CloseIcon w={3} />}
                    onClick={() => setIsSidebarVisible(false)}
                    isRound
                  />
                </Box>
              </Flex>
              <WorkspaceSwitcher onOpen={onWorkspaceSettingsModalOpen} />
              <FauxSearchInput />
            </Stack>
            <SidebarContent
              channelPreview={channelPreview}
              activeTab={activeTab}
              currentChannelId={currentChannelId}
              channels={channels}
              isChannelsLoading={isChannelsLoading}
            />
            <Divider my={4} />
            {isWorkspaceAdmin && (
              <SidebarButton
                dataTestId="invite-to-workspace"
                onClick={() => {
                  onWorkspaceSettingsModalOpen()
                }}
              >
                <SidebarItemLabel
                  icon={regular('user-plus')}
                  title={'Invite to workspace'}
                />
              </SidebarButton>
            )}
            {canOpenIntercom && (
              <GammaTooltip label="Share feedback, request features, reports bugs, and more!">
                <SidebarButton
                  dataTestId="contact-button"
                  onClick={() => {
                    onContactClick()
                  }}
                >
                  <SidebarItemLabel
                    icon={regular('message-smile')}
                    title={'Contact us'}
                  />
                </SidebarButton>
              </GammaTooltip>
            )}
            {!canOpenIntercom && (
              <SidebarButton
                dataTestId="contact-button"
                onClick={() => {
                  window.open(FEEDBACK_LINK, '_blank')
                }}
              >
                <SidebarItemLabel
                  icon={regular('message-smile')}
                  title={'Submit feedback'}
                />
              </SidebarButton>
            )}
            <Spacer />

            <Flex justify="center" align="flex-end" width="100%" my={6}>
              <Box fontSize="12px" width="30px">
                {Logo}
              </Box>
            </Flex>
          </>
        )}
      </Flex>
      <WorkspaceSettingsModal
        isOpen={isWorkspaceSettingsModalOpen}
        onClose={onWorkspaceSettingsModalClose}
      />
    </Flex>
  )
}

// TODO - Consider refactoring this to not use Chakra Tabs, as the index based
// selecting makes things more complex than they need to be.
// See https://linear.app/gamma-app/issue/G-1606/fe-refactor-sidebartabs-and-make-them-into-something-other-than-chakra
const SidebarContent = ({
  channelPreview,
  activeTab,
  currentChannelId,
  channels,
  isChannelsLoading,
}: Pick<
  SidebarProps,
  | 'channelPreview'
  | 'activeTab'
  | 'currentChannelId'
  | 'channels'
  | 'isChannelsLoading'
>) => {
  const { hasInitialized: featureFlagsInitialized } = useFeatureFlagProvider()
  const EXAMPLE_MEMOS = useFeatureFlag('exampleMemos')
  const themesEnabled = useFeatureFlag('themesHomescreen')
  const examplesV2Enabled = useFeatureFlag('examplesV2')
  const inspirationDecksEnabled = useFeatureFlag('inspirationDecks')
  const customFontsEnabled = useFeatureFlag('customFonts')

  // Don't show old examples if new examples are enabled
  const shouldShowExamples =
    !examplesV2Enabled &&
    featureFlagsInitialized &&
    Boolean(EXAMPLE_MEMOS.length)
  return (
    <VStack data-testid="sidebar-tablist" mt={4} mb={2} w="100%">
      <SidebarItem
        tabKey={DocsTabs.ALL}
        isSelected={activeTab === DocsTabs.ALL}
        href={`/#${DocsTabs.ALL}`}
      >
        <SidebarItemLabel
          icon={TabMap[DocsTabs.ALL].icon}
          title={TabMap[DocsTabs.ALL].displayName}
        />
      </SidebarItem>
      <SidebarChannels
        channelPreview={channelPreview}
        isChannelsLoading={isChannelsLoading}
        channels={channels}
        currentChannelId={currentChannelId}
        activeTab={activeTab}
      />
      <Divider my={3} />
      {shouldShowExamples && (
        <SidebarItem
          tabKey={DocsTabs.EXAMPLES}
          isSelected={activeTab === DocsTabs.EXAMPLES}
          href={`/#${DocsTabs.EXAMPLES}`}
        >
          <SidebarItemLabel
            icon={TabMap[DocsTabs.EXAMPLES].icon}
            title={TabMap[DocsTabs.EXAMPLES].displayName}
          />
        </SidebarItem>
      )}
      {examplesV2Enabled && !isMobileDevice && (
        <SidebarItem
          tabKey={DocsTabs.TEMPLATES}
          isSelected={activeTab === DocsTabs.TEMPLATES}
          href={`/#${DocsTabs.TEMPLATES}`}
        >
          <SidebarItemLabel
            icon={TabMap[DocsTabs.TEMPLATES].icon}
            title={TabMap[DocsTabs.TEMPLATES].displayName}
          />
        </SidebarItem>
      )}
      {examplesV2Enabled && inspirationDecksEnabled && (
        <SidebarItem
          tabKey={DocsTabs.INSPIRATION}
          isSelected={activeTab === DocsTabs.INSPIRATION}
          href={`/#${DocsTabs.INSPIRATION}`}
        >
          <SidebarItemLabel
            icon={TabMap[DocsTabs.INSPIRATION].icon}
            title={TabMap[DocsTabs.INSPIRATION].displayName}
          />
        </SidebarItem>
      )}
      {themesEnabled && (
        <SidebarItem
          tabKey={DocsTabs.THEMES}
          isSelected={activeTab === DocsTabs.THEMES}
          href={`/#${DocsTabs.THEMES}`}
        >
          <SidebarItemLabel
            icon={TabMap[DocsTabs.THEMES].icon}
            title={TabMap[DocsTabs.THEMES].displayName}
          />
        </SidebarItem>
      )}
      {customFontsEnabled && !isMobileDevice && (
        <SidebarItem
          tabKey={DocsTabs.FONTS}
          isSelected={activeTab === DocsTabs.FONTS}
          href={`/#${DocsTabs.FONTS}`}
        >
          <SidebarItemLabel
            icon={TabMap[DocsTabs.FONTS].icon}
            title={TabMap[DocsTabs.FONTS].displayName}
          />
        </SidebarItem>
      )}
      <SidebarItem
        tabKey={DocsTabs.TRASH}
        isSelected={activeTab === DocsTabs.TRASH}
        href={`/#${DocsTabs.TRASH}`}
      >
        <SidebarItemLabel
          icon={TabMap[DocsTabs.TRASH].icon}
          title={TabMap[DocsTabs.TRASH].displayName}
        />
      </SidebarItem>
    </VStack>
  )
}
