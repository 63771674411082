import { Box, Button, Center, useDisclosure, useToast } from '@chakra-ui/react'
import { DocumentGrid, LoadingToastInner } from '@gamma-app/ui'
import { useRouter } from 'next/router'
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'

import { Channel, Doc, DocSortField, useGetDocLazyQuery } from 'modules/api'
import { trackDocCreatedEvent } from 'modules/segment/helper'
import { SharePanelModal } from 'modules/sharing/components/SharePanel'
import { useUpdatePublicChannels } from 'modules/sharing/useUpdatePublicChannels'
import { useDuplicateDoc } from 'modules/tiptap_editor/utils/duplicate'
import { useAbility, useUserContext } from 'modules/user'
import { useDocsData } from 'sections/home_v2/hooks/useDocsData'
import { useDocsDisplayConfig } from 'sections/home_v2/hooks/useDocsDisplayConfig'

import { useDocMutations } from '../../hooks/useDocMutations'
import { DocsEmptyState } from '../DocsEmptyState'
import { SidebarTabsType } from '../Sidebar/Sidebar'
import { TopbarWrapper } from '../Topbar/TopbarWrapper'
import { DocGridItemCustomDragLayer, DocGridItemWrapper } from './DocsGrid'
import { DocsTable } from './DocsTable'
import { DocsViewSkeleton } from './DocsViewSkeleton'
import { DocsViewTopbar } from './DocsViewTopbar'
import { useFetchThumbnails } from './hooks'
import { RenameDocModal } from './RenameDocModal'

export const getTimeStampAndDescriptor = ({
  sortBy,
  filterBy,
  docUser,
  doc,
}) => {
  if (filterBy === 'favorites') {
    return {
      timestamp: docUser?.favorited,
      descriptor: 'Favorited',
    }
  } else if (filterBy === 'recent') {
    return {
      timestamp: docUser?.lastViewed,
      descriptor: 'Last viewed',
    }
  }
  const obj = {
    [DocSortField.LastViewed]: {
      timestamp: docUser?.lastViewed,
      descriptor: 'Last viewed',
    },
    [DocSortField.CreatedTime]: {
      timestamp: doc.createdTime,
      descriptor: 'Created',
    },
    [DocSortField.EditedTime]: {
      timestamp: doc.editedTime,
      descriptor: 'Edited',
    },
  }
  return (
    obj?.[sortBy] || {
      timestamp: doc.editedTime,
      descriptor: 'Edited',
    }
  )
}
interface DocsViewProps {
  userHasNoWorkspaces: boolean
  activeTab: SidebarTabsType
  isSidebarVisible: boolean
  setIsSidebarVisible: Dispatch<SetStateAction<boolean>>

  currentChannelId: string | null
  activeChannel?: Channel
  channelPreview: Channel | null
}

export const DocsView = ({
  userHasNoWorkspaces,
  isSidebarVisible,
  setIsSidebarVisible,

  currentChannelId,
  activeChannel,
  channelPreview,

  activeTab,
}: DocsViewProps) => {
  const { user, currentWorkspace } = useUserContext()
  const [isFetchingMore, setIsFetchingMore] = useState<boolean>(false)
  const [idOfDocToShare, setIdOfDocToShare] = useState<string | null>(null)
  const [docToRename, setDocToRename] = useState<Doc | null>(null)
  const [getDoc, { data: docData }] = useGetDocLazyQuery({
    variables: {
      id: idOfDocToShare as string,
    },
    returnPartialData: true,
    nextFetchPolicy: 'cache-first',
  })

  const { favoriteDoc, trashDoc, restoreDoc } = useDocMutations({
    refetchQueries: ['GetDocs', 'GetChannelDocActivity'],
  })
  const duplicateDoc = useDuplicateDoc()
  const { removeDocChannel } = useUpdatePublicChannels()
  const { push } = useRouter()
  const toast = useToast()
  const ability = useAbility()
  const {
    isOpen: isSharePanelOpen,
    onOpen: onSharePanelOpen,
    onClose: onSharePanelClose,
  } = useDisclosure({ id: 'sharePanelDisclosure' })
  const {
    isOpen: isRenameDocModalOpen,
    onOpen: onRenameDocModalOpen,
    onClose: onRenameDocModalClose,
  } = useDisclosure({ id: 'RenameDocModalDisclosure' })

  const {
    viewPreference,
    setViewPreference,
    sortBy,
    setSortBy,
    sortDirection,
    filterBy,
    setFilterBy,
    setSortDirection,
    dateDisplayOption,
    setDateDisplayOption,
  } = useDocsDisplayConfig({ currentChannelId, activeTab })
  const { docs, loading, showPaginationControls, fetchMore, activeDocs } =
    useDocsData({
      filterBy,
      activeTab,
      currentChannelId,
      channelPreview,
      user,
      currentWorkspace,
      sortBy,
      sortDirection,
    })

  useEffect(() => {
    if (!idOfDocToShare) return
    getDoc({
      variables: {
        id: idOfDocToShare,
      },
    })
  }, [idOfDocToShare, getDoc])

  const handleShareClick = useCallback(
    (docId: string) => {
      setIdOfDocToShare(docId)
      onSharePanelOpen()
    },
    [onSharePanelOpen]
  )

  const handleRemoveFromChannel = useCallback(
    (doc, channelId) => {
      removeDocChannel(doc, channelId)
    },
    [removeDocChannel]
  )

  const handleDuplicateDoc = useCallback(
    (doc: Doc) => {
      const duplicateToast = toast({
        id: `duplicate-toast-${doc.id}`,
        position: 'top',
        duration: null,
        render: function LoadingToast({ onClose, id }) {
          return (
            <LoadingToastInner
              title="Duplicating..."
              isClosable={false}
              onClose={onClose}
              id={id}
            />
          )
        },
      }) as string
      duplicateDoc(doc.id).then((result) => {
        if (!result?.data?.duplicateDoc) {
          console.error('[Sidebar] handleDuplicateDoc error')
          return
        }
        const { id: newId } = result?.data?.duplicateDoc
        trackDocCreatedEvent({
          doc_id: newId,
          source: 'dashboard_docs_view_duplicate',
          source_doc_id: doc.id,
        })
        console.debug(
          `[Menu] Doc duplicated. New doc id (${newId}). Navigating to Editor`
        )
        toast.update(duplicateToast, {
          status: 'success',
          title: `${doc.title} has been duplicated.`,
          duration: 5000,
        })
        push(`/docs/${newId}`)
      })
    },
    [duplicateDoc, push, toast]
  )
  const handleRenameClick = useCallback(
    (doc: Doc) => {
      setDocToRename(doc)
      onRenameDocModalOpen()
    },
    [onRenameDocModalOpen]
  )

  const showDocs = !loading && docs && docs?.length > 0
  const { fetchThumbnails, getThumbnails } = useFetchThumbnails()

  return (
    <Box width="100%">
      {!userHasNoWorkspaces && (
        <TopbarWrapper
          isSidebarVisible={isSidebarVisible}
          setIsSidebarVisible={setIsSidebarVisible}
        >
          <DocsViewTopbar
            activeTab={activeTab}
            channel={channelPreview ? channelPreview : activeChannel}
            activeDocs={activeDocs}
            isChannelPreview={Boolean(channelPreview)}
            sortDirection={sortDirection}
            sortBy={sortBy}
            filterBy={filterBy}
            viewPreference={viewPreference}
            setSortBy={setSortBy}
            setSortDirection={setSortDirection}
            setViewPreference={setViewPreference}
            setFilterBy={setFilterBy}
            docCount={docs?.length}
          />
        </TopbarWrapper>
      )}
      <Box width="100%" data-testid="docs-view-container">
        <DocGridItemCustomDragLayer
          user={user}
          currentWorkspace={currentWorkspace}
          currentChannelId={currentChannelId}
          viewPreference={viewPreference}
        />

        {/* Docs are loading: show the skeleton depending on the viewPreference */}
        {loading && <DocsViewSkeleton viewPreference={viewPreference} />}

        {/* Docs loaded but there were 0: show empty state */}
        {!loading && docs?.length === 0 && <DocsEmptyState />}

        {/* Docs loaded: list view */}
        {viewPreference === 'list' && showDocs && (
          <DocsTable
            dateDisplayOption={dateDisplayOption}
            setDateDisplayOption={setDateDisplayOption}
            user={user}
            currentWorkspace={currentWorkspace}
            userHasNoWorkspaces={userHasNoWorkspaces}
            filterBy={filterBy}
            docs={docs}
            currentChannelId={currentChannelId}
            sortBy={sortBy}
            sortDirection={sortDirection}
            setSortBy={setSortBy}
            setSortDirection={setSortDirection}
            handleDuplicateDoc={handleDuplicateDoc}
            handleFavoriteDoc={favoriteDoc}
            handleTrash={trashDoc}
            handleRestoreDoc={restoreDoc}
            handleShareClick={handleShareClick}
            handleRenameClick={handleRenameClick}
            handleRemoveFromChannel={handleRemoveFromChannel}
          />
        )}

        {/* Docs loaded: grid view */}
        {viewPreference === 'grid' && showDocs && (
          <DocumentGrid data-testid="docs-view-doc-grid">
            {docs.map((doc) => {
              const canDuplicate =
                ability.can('view', doc) && !userHasNoWorkspaces
              const canEditDoc =
                ability.can('edit', doc) && !userHasNoWorkspaces
              const canManageDoc =
                ability.can('manage', doc) && !userHasNoWorkspaces
              const thumbnailsToShow = getThumbnails(doc.id)

              return (
                <DocGridItemWrapper
                  key={doc.id}
                  user={user}
                  currentWorkspace={currentWorkspace}
                  doc={doc}
                  currentChannelId={currentChannelId}
                  sortBy={sortBy}
                  filterBy={filterBy}
                  canDuplicate={canDuplicate}
                  createdByYou={doc?.createdBy?.id === user?.id}
                  handleDuplicateDoc={handleDuplicateDoc}
                  handleFavoriteDoc={favoriteDoc}
                  handleTrash={trashDoc}
                  handleRenameClick={handleRenameClick}
                  handleRemoveFromChannel={handleRemoveFromChannel}
                  handleRestoreDoc={restoreDoc}
                  handleShareClick={handleShareClick}
                  fetchThumbnails={fetchThumbnails}
                  cardThumbnails={thumbnailsToShow}
                  canEditDoc={canEditDoc}
                  canManageDoc={canManageDoc}
                />
              )
            })}
          </DocumentGrid>
        )}

        {/* All the modals */}
        {isSharePanelOpen && (
          <SharePanelModal
            doc={docData?.doc as Doc}
            isSharePanelOpen={isSharePanelOpen}
            onSharePanelClose={() => {
              setIdOfDocToShare(null)
              onSharePanelClose()
            }}
          />
        )}
        {isRenameDocModalOpen && (
          <RenameDocModal doc={docToRename} onClose={onRenameDocModalClose} />
        )}

        {showPaginationControls && (
          <Center p={6}>
            <Button
              isLoading={isFetchingMore}
              onClick={() => {
                setIsFetchingMore(true)
                fetchMore().finally(() => {
                  setIsFetchingMore(false)
                })
              }}
              size="sm"
            >
              Load more
            </Button>
          </Center>
        )}
      </Box>
    </Box>
  )
}
