import {
  Box,
  Button,
  Divider,
  HStack,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DOC_DISPLAY_NAME, DOC_DISPLAY_NAME_PLURAL } from '@gamma-app/ui'
import NextLink from 'next/link'
import Router, { useRouter } from 'next/router'
import { useCallback, useRef } from 'react'

import { config } from 'config'
import { DocSortField, SortDirection, useGetDocsQuery } from 'modules/api'
import { useCreateDocAndNavigate } from 'modules/api/hooks'
import { makeCreateFirstDocExperiment } from 'modules/experiments'
import { replaceState } from 'modules/history'
import { SegmentEvents, useAnalytics } from 'modules/segment'
import { useUserContext } from 'modules/user'
import { isMobileDevice } from 'utils/deviceDetection'
import { useLocalStorage } from 'utils/hooks/useLocalStorage'
import { USER_SETTINGS_CONSTANTS } from 'utils/userSettingsConstants'

import { GetStartedOption } from './GetStartedOption'
import {
  GETTING_STARTED_OPTIONS,
  GETTING_STARTED_OPTIONS_OLD,
} from './optionsData'

const GET_STARTED_SEARCH_PARAM = 'getStarted'

export const GetStartedModal = () => {
  const { push, query, pathname } = useRouter()
  const [createDoc] = useCreateDocAndNavigate()
  const hasGetStartedQueryParam = Boolean(query[GET_STARTED_SEARCH_PARAM])
  const handleOnCloseClick = useCallback(
    (newPath?: string) => {
      const { searchParams } = new URL(window.location.href)
      searchParams.delete(GET_STARTED_SEARCH_PARAM)

      if (!newPath) {
        // User closed or clicked outside - Clear the query param and trigger a next route
        Router.replace(
          { pathname, query: searchParams.toString() },
          undefined,
          {
            shallow: true,
          }
        )
      } else if (newPath.startsWith('/#')) {
        // User chose a sidebar tab - Router.replace with the full new path
        Router.replace(newPath)
      } else {
        // User is navigating outside of the home screen - clear the param then do a push
        replaceState({ query: Object.fromEntries(searchParams.entries()) })
        push(newPath)
      }
    },
    [pathname, push]
  )

  const handleCreateDocClick = useCallback(() => {
    const { emptyDocType, queryParams } = makeCreateFirstDocExperiment({
      forceBucket: true,
    })
    const { searchParams } = new URL(window.location.href)
    searchParams.delete(GET_STARTED_SEARCH_PARAM)
    replaceState({ query: Object.fromEntries(searchParams.entries()) })
    createDoc({ source: 'get_started_modal', emptyDocType, queryParams })
  }, [createDoc])

  if (!hasGetStartedQueryParam) return null

  return (
    <GetStartedModalContent
      onClose={handleOnCloseClick}
      onCreateDoc={handleCreateDocClick}
    />
  )
}

const ACTION_TARGET_MAP = {
  welcomeDoc: 'welcome_doc',
  templates: 'templates',
  inspiration: 'inspiration',
  blankDeck: 'create_doc',
}
const GetStartedModalContent = ({
  onClose,
  onCreateDoc,
}: {
  onClose: (pathname?: string) => void
  onCreateDoc: () => void
}) => {
  const showCreateDeckFirst = useRef<boolean>(Math.random() > 0.5)

  const analytics = useAnalytics()
  const [welcomeDocIdLS] = useLocalStorage<string | null>(
    USER_SETTINGS_CONSTANTS.welcomeDocId,
    null
  )
  const size = useBreakpointValue(['sm', 'md', '3xl', '4xl'])
  const { user, currentWorkspace } = useUserContext()
  const userId = user?.id

  const options = showCreateDeckFirst.current
    ? GETTING_STARTED_OPTIONS
    : GETTING_STARTED_OPTIONS_OLD

  const {
    data: welcomeDocData,
    loading: welcomeDocLoading,
    called: welcomeDocCalled,
  } = useGetDocsQuery({
    variables: {
      workspaceId: currentWorkspace?.id,
      first: 1,
      createdBy: userId,
      sortBy: {
        field: DocSortField.CreatedTime,
        direction: SortDirection.Asc,
      },
    },
    skip: Boolean(!userId || welcomeDocIdLS),
  })

  const welcomeDocId = welcomeDocIdLS || welcomeDocData?.docs.edges[0]?.node.id
  const variation = showCreateDeckFirst.current ? 'new' : 'old'
  const handleActionClick = useCallback(
    (action?: string) => {
      switch (action) {
        case 'welcomeDoc':
          if (welcomeDocId) {
            onClose(`/docs/${welcomeDocId}`)
          } else {
            console.warn('[StartingModal] No welcomeDoc')
          }
          break

        case 'templates':
          onClose('/#templates')
          break

        case 'inspiration':
          onClose('/#inspiration')
          break

        case 'blankDeck':
          onCreateDoc()
          break

        default:
          console.warn('[StartingModal] Unknown action', action)
          break
      }
    },
    [onClose, onCreateDoc, welcomeDocId]
  )

  return (
    <Modal
      isOpen={true}
      size={size}
      trapFocus={false}
      onClose={() => {
        analytics?.track(SegmentEvents.GET_STARTED_CLICKED, {
          target: 'dismiss',
          variation,
        })
        onClose()
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>How would you like to get started?</ModalHeader>
        <ModalCloseButton />
        {isMobileDevice ? (
          <Box padding="4" background="salmon.100" mb="4">
            <HStack spacing="5" paddingLeft="2">
              <Box color="salmon.600">
                <FontAwesomeIcon icon={regular('desktop')} className="fa-xl" />
              </Box>
              <VStack align="flex-start" spacing="1">
                <Text fontWeight="600" fontSize="md">
                  Create {DOC_DISPLAY_NAME_PLURAL} on desktop
                </Text>
                <Text fontSize="sm">
                  Heads up! Gamma {DOC_DISPLAY_NAME_PLURAL} are view/comment
                  only on mobile. Jump on a computer or tablet to create your
                  first {DOC_DISPLAY_NAME}.
                </Text>
              </VStack>
            </HStack>
          </Box>
        ) : (
          <Divider mb="4" />
        )}
        <ModalBody>
          <SimpleGrid columns={[1, 1, 3]} spacing={5}>
            {options.map((option, index) => {
              const {
                icon,
                colorScheme,
                previewImage,
                title,
                body,
                action,
                hideOnMobile,
              } = option

              const isMissingWelcomeDoc =
                !welcomeDocIdLS && // We didnt find an id in local storage from signing up
                welcomeDocCalled && // We've kicked off the fetch for the users first doc
                !welcomeDocLoading && // The fetch isnt loading anymore
                !welcomeDocData // And finally there is no data from the fetch
              if (
                action === 'welcomeDoc' &&
                // Dont show the welcomeDoc option if we cant find a welcome doc. This is unexpected
                isMissingWelcomeDoc
              ) {
                console.error(
                  '[GetStartedModalContent] Unexpectedly cant find a welcomeDocId'
                )
                return null
              }

              const href =
                action === 'blankDeck'
                  ? '/new?firstDeck=true'
                  : action === 'templates'
                  ? '/#templates'
                  : action === 'inspiration'
                  ? '/#inspiration'
                  : action === 'welcomeDoc'
                  ? `/docs/${welcomeDocId}`
                  : '/'

              if (hideOnMobile && isMobileDevice) {
                return <> </>
              }

              return (
                <GetStartedOption
                  key={index}
                  accentColor={colorScheme || 'trueblue'}
                  title={title}
                  body={body}
                  href={href}
                  bgImage={previewImage || config.OG_IMAGE_URL}
                  icon={icon}
                  onClick={(e) => {
                    analytics?.track(SegmentEvents.GET_STARTED_CLICKED, {
                      target: ACTION_TARGET_MAP[action],
                      variation,
                    })
                    if (!e.metaKey) {
                      e.preventDefault()
                      handleActionClick(action)
                    }
                  }}
                />
              )
            })}
          </SimpleGrid>
        </ModalBody>
        <ModalFooter>
          {!showCreateDeckFirst.current && !isMobileDevice && (
            <Stack alignItems="center" minWidth="100%">
              <Text>
                You can also start in a{' '}
                <NextLink href="/new?firstDeck=true" passHref>
                  <Button
                    as={Link}
                    colorScheme="black"
                    variant="link"
                    textDecoration="underline"
                    mb="4"
                    onClick={(e) => {
                      analytics?.track(SegmentEvents.GET_STARTED_CLICKED, {
                        target: 'create_doc_footer',
                        variation,
                      })
                      if (!e.metaKey) {
                        e.preventDefault()
                        onCreateDoc()
                      }
                    }}
                  >
                    blank {DOC_DISPLAY_NAME}
                  </Button>
                </NextLink>
              </Text>
            </Stack>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
