import { WarningTwoIcon } from '@chakra-ui/icons'
import {
  Alert,
  Avatar,
  Box,
  ButtonGroup,
  Button,
  Checkbox,
  Divider,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  Stack,
  Text,
  useToast,
  ModalOverlay,
} from '@chakra-ui/react'
import { DOC_DISPLAY_NAME_PLURAL } from '@gamma-app/ui'
import { useCallback, useState } from 'react'

import { config } from 'config'
import {
  User,
  useGetUserQuery,
  useDeleteUserMutation,
  Organization,
} from 'modules/api'

type AccountDeleteModalProps = {
  isOpen: boolean
  onClose: () => void
  user: User
}

type InfoBoxProps = {
  // In order to use correctly only 1 of user or workspace should be set
  user?: User
  workspace?: Organization
}

const PermanentlyDeleteBox = () => {
  return (
    <HStack
      px={2}
      py={2}
      border="2px solid var(--chakra-colors-red-500)"
      borderRadius="md"
    >
      <WarningTwoIcon w={4} h={4} color="red.500" />
      <Text fontSize="sm" color="red.500">
        Will be deleted
      </Text>
    </HStack>
  )
}

const InfoBox = ({ user, workspace }: InfoBoxProps) => {
  return (
    <Alert
      status={
        user || (workspace?.memberCount && workspace.memberCount <= 1)
          ? 'error'
          : 'warning'
      }
      display="flex"
      flexDirection="column"
      alignItems="normal"
      mt={2}
    >
      <Flex
        pb={3}
        flexDirection={['column', 'column', 'row', 'row']}
        align="flex-start"
        gap={2}
      >
        <Flex flex={1}>
          <Avatar
            src={user?.profileImageUrl}
            size="sm"
            name={user?.displayName || workspace?.name}
          />
          <Stack spacing={0} ml={2} flex="1">
            <Text fontSize="sm" flex="1" wordBreak="break-word">
              {user?.displayName || workspace?.name}
            </Text>
            {user && (
              <Text
                fontSize="xs"
                color="gray.600"
                flex="1"
                wordBreak="break-word"
              >
                {user.email}
              </Text>
            )}
            {workspace && (
              <Text fontSize="xs" color="gray.600">
                {workspace.memberCount}{' '}
                {workspace?.memberCount && workspace.memberCount > 1
                  ? 'members'
                  : 'member (you)'}
              </Text>
            )}
          </Stack>
        </Flex>
        <Flex flexShrink={1}>
          {(user || (workspace && workspace?.memberCount === 1)) && (
            <PermanentlyDeleteBox />
          )}
        </Flex>
      </Flex>
      <Divider borderColor="blackAlpha.200" />
      <Text
        pt={2}
        fontSize="sm"
        color={
          user || (workspace?.memberCount && workspace.memberCount <= 1)
            ? 'red.500'
            : 'yellow.700'
        }
      >
        {user &&
          `⚠️ You will lose access to any ${DOC_DISPLAY_NAME_PLURAL} shared with your account`}
        {workspace?.memberCount &&
          (workspace.memberCount > 1
            ? '⚠️ You will lose access to this workspace'
            : `⚠️ All the ${DOC_DISPLAY_NAME_PLURAL} in this workspace will be deleted`)}
      </Text>
    </Alert>
  )
}

export const AccountDeleteModal = ({
  isOpen,
  onClose,
  user,
}: AccountDeleteModalProps) => {
  const [hasConfirmedDeletion, setHasConfirmedDeletion] = useState(false)
  const [isDeleting, setIsDeleting] = useState<boolean>(false)
  const { data } = useGetUserQuery()
  const toast = useToast()
  const [deleteUserMutation] = useDeleteUserMutation()
  const userWorkspaces = data?.user?.organizations

  const onAccountDelete = useCallback(() => {
    setIsDeleting(true)
    deleteUserMutation()
      .then(({ data: deleteData }) => {
        if (!deleteData) return
        window.location.href = `${config.API_HOST}/logout?redirectTo=/user-deleted`
      })
      .catch(() => {
        setIsDeleting(false)
        toast({
          title:
            'Delete account failed. Try again or reach out to Gamma support.',
          status: 'error',
          duration: 3000,
          position: 'top',
          isClosable: true,
        })
      })
  }, [toast, deleteUserMutation])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent p={2}>
        <ModalHeader>Delete your Gamma account?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={3}>
            <Text>
              Deleting your account is permanent and cannot be undone. Are you
              sure you'd like to continue?
            </Text>
            <Box>
              <Text colorScheme="gray" fontSize="sm">
                Your account:
              </Text>
              <InfoBox user={user} />
            </Box>
            {userWorkspaces && userWorkspaces.length > 0 && (
              <Box>
                <Text colorScheme="gray" fontSize="sm">
                  Your workspaces:
                </Text>
                {userWorkspaces?.map((workspace) => {
                  return <InfoBox key={workspace.id} workspace={workspace} />
                })}
              </Box>
            )}
            <Box>
              <Text colorScheme="gray" fontSize="sm">
                Please acknowledge and confirm:
              </Text>
              <HStack
                bg="gray.100"
                mt={2}
                p={3}
                pl={2}
                spacing={2}
                borderRadius="sm"
              >
                <Checkbox
                  size="lg"
                  spacing="1rem"
                  p={3}
                  defaultChecked={hasConfirmedDeletion}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setHasConfirmedDeletion(e.target.checked)
                  }
                />
                <Text fontSize="sm">
                  I understand that deleting my account is permanent, and have
                  reviewed what will happen to my workspaces
                </Text>
              </HStack>
            </Box>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button variant="ghost" colorScheme="gray" onClick={onClose}>
              Cancel
            </Button>
            <Button
              disabled={!hasConfirmedDeletion}
              variant="ghost"
              colorScheme="red"
              onClick={onAccountDelete}
              isLoading={isDeleting}
              loadingText="Deleting your account..."
            >
              Yes, delete my account
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
