import {
  Avatar,
  Box,
  ButtonGroup,
  Button,
  Circle,
  Checkbox,
  Collapse,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  HStack,
  Image,
  Input,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  Stack,
  Spinner,
  Text,
  useToast,
} from '@chakra-ui/react'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { useCallback, useMemo, useState } from 'react'

import { useUpdateUserMutation, User } from 'modules/api'
import { useFeatureFlag } from 'modules/featureFlags'
import { UppyUploader } from 'modules/media'
import { useFileInput } from 'modules/media/components/useFileInput'
import { UserRefetchFn } from 'modules/user'
import { isHEICFileType } from 'utils/image'

type UploadingStatus = 'idle' | 'uploading' | 'error'

type AccountSettingsModalProps = {
  isOpen: boolean
  onClose: () => void
  onAccountDeleteModalOpen: () => void
  user: User
  orgId: string
  refetchUser?: UserRefetchFn
}

const UploadImagesInitialState = {
  cropped: '',
  original: '',
}

export const AccountSettingsModal = ({
  isOpen,
  onClose,
  onAccountDeleteModalOpen,
  user,
  orgId,
  refetchUser,
}: AccountSettingsModalProps) => {
  const toast = useToast()
  const deleteAccountEnabled = useFeatureFlag('deleteAccount')
  const [updateUser, { loading: isUpdatingUser, error }] =
    useUpdateUserMutation()

  const [firstName, setFirstName] = useState(user.firstName || '')
  const [lastName, setLastName] = useState(user.lastName || '')
  const [profileImageUrl, setProfileImageUrl] = useState(
    user.profileImageUrl || ''
  )
  const [uploadedImages, setUploadedImages] = useState(UploadImagesInitialState)
  const [imagePreference, setImagePreference] = useState<
    'cropped' | 'original'
  >('original')

  const imageToUse =
    uploadedImages.cropped && imagePreference === 'cropped'
      ? uploadedImages.cropped
      : uploadedImages.original && imagePreference === 'original'
      ? uploadedImages.original
      : profileImageUrl

  const [uploadStatus, setUploadStatus] = useState<UploadingStatus>('idle')
  const uppyInstance = useMemo(
    () =>
      UppyUploader.createUppyInstance(
        orgId,
        {
          onUploadStart: (data) => {
            setUploadedImages(UploadImagesInitialState)
            setUploadStatus('uploading')
            setImagePreference('original')
            if (!isHEICFileType(data.file.type || '')) {
              setProfileImageUrl(data.tempUrl)
            }
          },
          onOriginalFileUpload: (data) => {
            console.debug('[AccountSettingsModal] onOriginalFileUpload', data)
            setProfileImageUrl(data.src) // Should align with the default option
            setUploadedImages((prev) => ({ ...prev, original: data.src }))
          },
          onUploadComplete: (data) => {
            console.debug('[AccountSettingsModal] onUploadComplete', data)
            setProfileImageUrl((prev) => prev || data.src) // In case onOriginalFileUpload didn't fire (e.g. HEIC)
            setUploadedImages(() => ({
              original: data.src,
              cropped: data.croppedFace || '',
            }))
            setUploadStatus('idle')
            // Removes internal list of files, so you can reupload the same thing again
            uppyInstance.reset()
          },
          onUploadFailed: () => {
            setUploadStatus('error')
            // Removes internal list of files, so you can reupload the same thing again
            uppyInstance.reset()
          },
        },
        'profile'
      ),
    []
  )

  const { onClick: onProfileImageClick, inputElement } = useFileInput({
    uppy: uppyInstance,
  })

  const handleAccountDeleteClick = useCallback(() => {
    if (onAccountDeleteModalOpen) {
      onAccountDeleteModalOpen()
      onClose()
    }
  }, [onAccountDeleteModalOpen, onClose])

  const resetState = useCallback(() => {
    uppyInstance.cancelAll()
    setUploadStatus('idle')
    setFirstName(user.firstName || '')
    setLastName(user.lastName || '')
    setProfileImageUrl(user.profileImageUrl || '')
    setImagePreference('original')
    setUploadedImages(UploadImagesInitialState)
  }, [user, uppyInstance])

  const isDirty = useMemo(() => {
    return (
      firstName !== user.firstName ||
      lastName !== user.lastName ||
      profileImageUrl !== user.profileImageUrl
    )
  }, [user, firstName, lastName, profileImageUrl])

  const onSaveClick = useCallback(() => {
    if (!isDirty) {
      onClose()
      return
    }
    updateUser({
      variables: {
        input: {
          id: user.id,
          firstName,
          lastName,
          profileImageUrl,
        },
      },
    })
      .then(() => {
        refetchUser?.()
        onClose()
        toast({
          title: 'Account settings updated.',
          position: 'top',
          status: 'success',
          isClosable: true,
          duration: 3000,
        })
      })
      .catch((e) => {
        console.error(
          `[AccountSettingsModal.onSaveClick] Error updating user info "${user?.id}"`,
          e.message
        )
      })
  }, [
    isDirty,
    firstName,
    lastName,
    profileImageUrl,
    refetchUser,
    onClose,
    updateUser,
    user.id,
    toast,
  ])

  const isError =
    (firstName.length < 1 && lastName.length < 1) ||
    Boolean(error?.message) ||
    uploadStatus === 'error'

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      closeOnOverlayClick={false}
    >
      {inputElement}
      <Box w={0} h={0}>
        {/** Hack to preload both versions of the image upload */}
        <Image src={uploadedImages.original} />
        <Image src={uploadedImages.cropped} />
      </Box>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Account settings</ModalHeader>
        <ModalCloseButton />
        <Divider mb={4} />
        <ModalBody display="flex" flexDirection="column" alignItems="center">
          <Stack direction="column" alignItems="center">
            <Menu placement="bottom-start" offset={[100, 0]}>
              <GammaTooltip
                label="Click to change photo"
                openDelay={750}
                placement="top"
              >
                <MenuButton
                  position="relative"
                  disabled={uploadStatus === 'uploading'}
                  userSelect="none"
                  cursor={'pointer'}
                  role="group"
                  _hover={{ opacity: 0.9 }}
                >
                  <Avatar
                    src={imageToUse}
                    name={`${firstName} ${lastName}`.trim()}
                    size="2xl"
                    _groupHover={{ shadow: 'lg' }}
                    ignoreFallback={true}
                  >
                    {uploadStatus === 'uploading' && (
                      <Circle
                        position="absolute"
                        size="full"
                        justifyContent="center"
                        alignItems="center"
                        bg="gray.300"
                        opacity={0.5}
                      >
                        <Spinner size="xl" thickness="2px" />
                      </Circle>
                    )}
                    <Circle
                      position="absolute"
                      bottom={0}
                      right={0}
                      borderRadius="full"
                      color="gray.800"
                      textAlign="center"
                      bg="gray.200"
                      size={9}
                      _groupHover={{ shadow: 'lg', transform: 'scale(1.02)' }}
                    >
                      <FontAwesomeIcon
                        icon={
                          profileImageUrl
                            ? regular('camera')
                            : regular('pencil')
                        }
                        width={16}
                        height={16}
                        size="xs"
                      />
                    </Circle>
                  </Avatar>
                </MenuButton>
              </GammaTooltip>
              <MenuList fontSize="sm">
                <MenuItem
                  onClick={() => {
                    if (uploadStatus === 'uploading') return
                    onProfileImageClick()
                  }}
                >
                  <HStack>
                    <FontAwesomeIcon icon={solid('arrow-up-from-bracket')} />
                    <Text>Upload new photo</Text>
                  </HStack>
                </MenuItem>
                {profileImageUrl && <MenuDivider />}
                {profileImageUrl && (
                  <MenuItem
                    onClick={() => {
                      setProfileImageUrl('')
                      setUploadedImages(UploadImagesInitialState)
                    }}
                  >
                    <HStack color="var(--chakra-colors-red-500)">
                      <FontAwesomeIcon
                        icon={regular('trash-can')}
                        color="var(--chakra-colors-red-500)"
                      />
                      <Text>Remove photo</Text>
                    </HStack>
                  </MenuItem>
                )}
              </MenuList>
            </Menu>

            <Collapse
              in={
                Boolean(profileImageUrl) &&
                Boolean(uploadedImages.cropped) &&
                profileImageUrl !== user.profileImageUrl &&
                uploadStatus === 'idle'
              }
              animateOpacity
            >
              <Checkbox
                size="sm"
                fontSize="12px"
                color="gray.500"
                isChecked={imagePreference === 'cropped'}
                onChange={(e) => {
                  setImagePreference(e.target.checked ? 'cropped' : 'original')
                  setProfileImageUrl(
                    e.target.checked
                      ? uploadedImages.cropped
                      : uploadedImages.original
                  )
                }}
                mb={3}
              >
                Crop to fit
              </Checkbox>
            </Collapse>
            <FormErrorMessage>
              {uploadStatus === 'error' && 'Error uploading profile image.'}
            </FormErrorMessage>
            <Stack spacing={0} alignItems="center">
              <Text fontSize="xl" fontWeight="bold">
                {user.displayName}
              </Text>
              <Text color="gray.500">{user.email}</Text>
            </Stack>
          </Stack>

          <Stack direction="column" width="100%" my={6} px={4}>
            <FormControl isInvalid={isError}>
              <FormLabel>First name</FormLabel>
              <Input
                w="100%"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </FormControl>
            <FormControl isInvalid={isError}>
              <FormLabel>Last name</FormLabel>
              <Input
                w="100%"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              <FormErrorMessage>
                {error?.message
                  ? error?.message
                  : `You must provide a first or last name.`}
              </FormErrorMessage>
            </FormControl>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Flex
            justifyContent={deleteAccountEnabled ? 'space-between' : 'flex-end'}
            flex={1}
            align="center"
          >
            {deleteAccountEnabled && (
              <Button
                variant="ghost"
                color="gray.400"
                colorScheme="gray"
                size="sm"
                onClick={handleAccountDeleteClick}
              >
                Delete my account
              </Button>
            )}
            <ButtonGroup>
              {isDirty && (
                <Button variant="ghost" colorScheme="gray" onClick={resetState}>
                  Cancel
                </Button>
              )}
              <Button
                variant={isDirty ? 'solid' : 'plain'}
                onClick={onSaveClick}
                isDisabled={isError || uploadStatus === 'uploading'}
                isLoading={isUpdatingUser}
              >
                {isDirty ? 'Save changes' : 'Done'}
              </Button>
            </ButtonGroup>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
